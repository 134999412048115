<template>
  <json-excel
    class="btn btn-warning"
    :fetch="fetchData"
    :fields="json_fields"
    worksheet="Product"
    name="product_inventory.xls"
  >
    Download Excel
  </json-excel>
</template>

<script>
import JsonExcel from "vue-json-excel";

export default {
  components: { JsonExcel },
  props: {
    excelData: {
      type: Array
    }
  },
  setup(prop) {
    const fetchData = async () => {
      return prop.excelData;
    };

    return {
      fetchData,
      json_fields: {
        "Belong to": "belong_to",
        SKU: "sku",
        Product: "name",
        Description: "description",
        Quantity: "total_qty",
        "At rack": "rack",
        "At box": "box"
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  }
};
</script>
